.use_bootstrap {
  @import "~bootstrap/scss/bootstrap";

  body {
    background-color: #000000;
  }

  #title {
    font-size: 30px !important;
    color: white;
    text-align: left;
    font-weight: bold;

    @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
      font-size: 2.3125rem;
    }
  }

  #title-description {
    font-size: 1.25rem;
  }

  .primeira-section {
    background-color: #000000 !important;
    width: 100% !important;
    max-width: 1920px !important;
    background-image: linear-gradient(to top, #0f475a5c, black 45%);
  }

  @media screen and (min-width: 800px) {
    .primeira-section {
      padding: 45px 13% 40px !important;
    }
  }

  @media screen and (max-width: 900px) {
    #title {
      padding-bottom: 7px;
      font-size: 42px;
    }

    .main-text {
      padding: 45px 1.5rem 125px !important;
    }

    #title-description {
      width: 90%;
      padding-bottom: 100px;
      font-size: 16px;
    }

    .primeira-section {
      .form-inline {
        flex-direction: row;
        input{
          min-width: 64vw;
        }

        .menu-label{
          top: -2.4375rem;
        }

        button{
          margin-top: 0px !important;
          margin-left: 15%;
          z-index: 10;
        }
      }

      .dados-protegidos-mob {
        display: none;
      }

      .dados-protegidos-desk {
        display: flex;
      }
    }
  }

  .segunda-section {
    background-color: #000000 !important;
    width: 100% !important;
    max-width: 1920px !important;
    color: white;
  }

  .menu-label {
    position: absolute;
    z-index: 1;
    display: inline-block;
    margin-inline-start: -20px;
    padding: 2px 4px;
    //border-radius: calc(var(--wd-brd-radius) / 1.5);
    background-color: transparent;
    border: 1px white solid;
    border-radius: 5px;
    color: #fff;
    vertical-align: middle;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    font-family: var(--wd-text-font), sans-serif;
    line-height: 12px;
    //left: calc(21% - 30px);
    top: -1.4375rem;
    left: 50%;
    transform: translateX(-42%);

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      margin-left: 0;
      top: -42px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .menu-label-2 {
    position: absolute;
    z-index: 1;
    display: inline-block;
    margin-inline-start: -20px;
    padding: 2px 4px;
    //border-radius: calc(var(--wd-brd-radius) / 1.5);
    background-color: transparent;
    border: 1px white solid;
    border-radius: 5px;
    color: #fff;
    vertical-align: middle;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    font-family: var(--wd-text-font), sans-serif;
    line-height: 12px;
    //left: calc(43% - 30px);
    //margin-left: 0;

    top: -1.4375rem;
    left: 50%;
    transform: translateX(-42%);

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      margin-left: 0;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .p-mob {
    display: none;
  }

  //@media (max-width: 600px)
  //{
  //    .menu-label
  //    {
  //        font-size: 10px;
  //        left: calc(35% - 30px);
  //    }
  //    .menu-label-2
  //    {
  //        font-size: 10px;
  //        left: calc(35% - 30px);
  //    }
  //}

  .icone-flutuante {

    position: absolute;
    top: 88px;
    right: -8px;
  }

  .menu-label-red {
    --wd-label-color: rgb(255, 255, 255);
  }

  .menu-label::before {
    content: "";
    position: absolute;
    top: 100%;
    inset-inline-start: 10px;
    border: 10px solid var(--wd-label-color);
    border-bottom-color: transparent !important;
    border-inline-end-color: transparent !important;
    border-inline-end-width: 10px;
    border-inline-start-width: 0;
  }

  .terceira-section {
    background-color: #000000 !important;
    width: 100% !important;
    max-width: 1920px !important;
  }

  @media screen and (max-width: 800px) {
    .terceira-section {
     padding: 0 16px;
      .text-center{
        margin-bottom: 50px;
      }
    }
  }

  @media screen and (min-width: 800px) {
    .terceira-section {
      padding: 70px 11% !important;
    }
  }

  .quarta-section {
    background-color: #100f0f !important;
    width: 100% !important;
    max-width: 1920px !important;
    padding-bottom: 7rem;
  }

  @media screen and (min-width: 800px) {
    .quarta-section {
      padding: 0 11% 7rem;
    }
  }

  #clients-carousel {
    max-width: 470px;
    margin: auto;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      max-width: 900px;
    }

    .carousel-inner {
      height: 0;
      padding-bottom: 25%;
    }

    .carousel-item {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      .carousel-control-next {
        right: -40px
      }

      .carousel-control-prev {
        left: -40px;
      }
    }
  }

  .quinta-section {
    background-color: #000000 !important;
    width: 100% !important;
    max-width: 1920px !important;
    background-image: linear-gradient(to top, #7e7c7c5c, rgb(49, 48, 48) 15%);

  }

  @media screen and (max-width: 800px) {
    .quinta-section {
     padding: 0 16px;
      .text-center{
        margin-bottom: 50px;
      }
    }
  }

  @media screen and (min-width: 800px) {
    .quinta-section {
      padding: 0 11%;
    }
  }

  .sexta-section {
    background-color: #000000 !important;
    width: 100% !important;
    max-width: 1920px !important;
  }

  @media screen and (min-width: 800px) {
    .sexta-section {
      padding: 0 20%;
    }
  }

  .fundo-img {
    background-color: #7e7c7c5c;
    border-radius: 5px;
    width: 30% !important;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

  }

  .fundo-img::after {
    filter: blur(50px);
  }

  .img-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .ultima-section {
    background-color: #000000 !important;
    width: 100% !important;
    max-width: 1920px !important;
    color: white;
    background-image: linear-gradient(to top, #0000, #5856565c 80%);
    padding: inherit;
  }

  .accordion-item {
    color: white !important;
    background-color: #3a3939 !important;

  }

  .accordion-button {
    font-weight: bold !important;
    color: white !important;
    background-color: #3a3939 !important;
    border: 1px solid #000000 !important;
  }

  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, .125);
  }

  .cabecalho {
    background-color: black !important;
    border-bottom: 0.5px rgb(53, 53, 53) solid !important;
  }

  a:link,
  a:visited {
    text-decoration: none
  }

  a:active {
    text-decoration: none
  }

  .whatsapp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    //border-radius: 20px !important;
    font-size: 11px !important;
    background-color: #25D366 !important;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .nav li {
    margin: 0 10px;
  }

  .nav a {
    color: #333;
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 3px solid transparent;
  }

  .nav a.active,
  .nav a:hover,
  .nav a:focus {
    border-bottom: 3px solid #c79633;
  }

  .btn-success:focus:not(:active),
  .btn-success:active {
    outline: none;
    box-shadow: none;
  }

  .btn-form {
    height: 50px;
    border-radius: 5px;
    font-weight: bold;
    background-color: #198754;
    color: white;
    border: 1px solid #198754;
    width: 25%;
    font-size: 0.76rem;
  }

  .accordion-button:not(.collapsed) {
    color: #ffc107 !important;
    background-color: transparent;
    border-color: transparent;
  }

  .accordion-button::after {
    background-color: white;
    border-radius: 20%;
  }

  @media screen and (min-width: 800px) {
    .personalizado-padding {
      padding: 0 30%;
    }
  }

  ::-webkit-input-placeholder {
    /* Safari, Google Chrome e Microsoft Edge */
    text-align: center;
    font-weight: bold;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 a 18 */
    text-align: center;
    font-weight: bold;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-align: center;
    font-weight: bold;
  }

  :-ms-input-placeholder {
    /* Microsoft Edge */
    text-align: center;
    font-weight: bold;
  }

  ::placeholder {
    /* Padrão */
    text-align: center;
    font-weight: bold;
  }

  .btn-whats-mob {
    display: none;
  }

  @media only screen and (max-width: 992px) {
    .btn-whats-mob {
      display: inline-flex;
      justify-content: flex-end;
    }

    .btn-whats-desk {
      display: none;
    }

    .Eficiencia-tributaria {
      display: none;
    }
  }

  .cnpj-input {
    width: 70%;
    height: 50px;
    font-size: 1rem;
    text-align: center;
    max-width: 15.625rem;
  }

  .dados-protegidos-mob {
    display: none;
    color: white;
    font-size: 12px;
  }

  .dados-protegidos-desk {
    color: white;
    font-size: 0.78rem;
  }

  .navbar {
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }

  .form-inline {
    align-items: center;
  }

  @media (max-width: 767px) {
    .form-inline {
      flex-direction: column;
      align-items: center;
    }

    .form-inline button {
      margin-top: 10px;
    }

    .btn-form {
      width: 80%;
      max-width: 15.625rem;
    }

    #inlineFormInputName {
      width: 100%;
    }

    .dados-protegidos-mob {
      display: block;
    }

    .dados-protegidos-desk {
      display: none;
    }

    .icone-flutuante {
      display: none;
    }

    .whatsapp-button {
      width: 60%;
    }

    .p-mob {
      display: block;
    }

    .p-desk {
      display: none;
    }
  }
}